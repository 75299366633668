import NgcApi from '@/repository/api/NgcApi';
import User from '@/repository/data/User';
import Resource from '@/repository/Resource';
import { BehaviorSubject, Observable } from 'rxjs';
import networkBoundResource from '@/repository/networkBoundResource';
import unwrapApiWrapper from '@/repository/unwrapApiWrapper';
import { switchMap } from 'rxjs/operators';

export default class UserRepository {
    private readonly api: NgcApi;

    public constructor(api: NgcApi) {
        this.api = api;
    }

    public findCurrent(): Observable<Resource<User>> {
        const observable = this.api.getSelf().pipe(
            switchMap((user) => {
                if (user) {
                    const observable = this.api.getCurrentUser();
                    return unwrapApiWrapper(observable);
                }
                return new BehaviorSubject(null).asObservable();
            }),
        );
        return networkBoundResource(observable);
    }

    public logout(): Observable<Resource<{ logoutUrl: string }>> {
        const observable = this.api.logout();
        return networkBoundResource(observable);
    }
}
