
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import MultiSourceVideo from '@/repository/data/MultiSourceVideo';
import GlobalState from '@/state/GlobalState';
import CustomVuePlyr from '@/components/parts/CustomVuePlyr.vue';

@Component({
    components: { CustomVuePlyr },
})
export default class MultiSourceVideoRenderer extends Vue {
    @Prop({ type: Object, required: false, default: null })
    public dataSource!: MultiSourceVideo | null;

    @Inject() private readonly globalState!: GlobalState;

    public get mediaSource(): string | null {
        let result: string | null = null;
        if (this.dataSource) {
            if (this.dataSource.ngtMediaReferenceId !== null) {
                result = this.globalState.getNgtFileUrl(this.dataSource.ngtMediaReferenceId);
            } else if (this.dataSource.cmsVideoReferenceId !== null) {
                result = this.globalState.getFileUrl(this.dataSource.cmsVideoReferenceId);
            } else if (this.dataSource.urlVideoSource !== null) {
                result = this.dataSource.urlVideoSource;
                if (result.includes('vimeo') && result.includes('/')) {
                    let vimeoPart;
                    let split = result.split('/');
                    if (result.endsWith('/')) {
                        vimeoPart = split[split.length - 2].split('?')[0];
                    } else {
                        vimeoPart = split[split.length - 1].split('?')[0];
                    }
                    if (vimeoPart !== null) {
                        result =
                            'https://player.vimeo.com/video/' +
                            vimeoPart +
                            '?loop=false&byline=false&portrait=false' +
                            '&title=false&speed=true&transparent=0&gesture=media';
                    }
                }
            }
        }
        return result;
    }

    public get poster(): string | null {
        let result: string | null = null;
        if (this.dataSource) {
            if (this.dataSource.ngtMediaReferenceId !== null) {
                result = this.globalState.getNgtThumbUrl(this.dataSource.ngtMediaReferenceId);
            } else if (this.dataSource.cmsVideoReferenceId !== null) {
                result = this.globalState.getThumbUrl(this.dataSource.cmsVideoReferenceId);
            }
        }
        return result;
    }

    public get isEmbed(): boolean {
        return !!this.dataSource && this.dataSource.urlVideoSource !== null;
    }
}
