import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import ShoppingCart from '@/state/ShoppingCart';
import WebShopRepository from '@/repository/WebShopRepository';
import User from '@/repository/data/User';
import UserRepository from '@/repository/UserRepository';
import Resource from '@/repository/Resource';

export default class GlobalState {
    private readonly _userRepository: UserRepository;
    private readonly _apiEndpoint: string;
    private readonly _loginEndpoint: string;
    private _userSubscription = new Subscription();
    private readonly _languageSubject: BehaviorSubject<string>;
    private readonly _cartSizeSubject: BehaviorSubject<number>;
    private readonly languageSubscription: Subscription;
    private readonly _cart: ShoppingCart;
    private _userSubject = new BehaviorSubject<Resource<User>>(Resource.loading<User>(null));

    constructor(
        userRepository: UserRepository,
        webShopRepository: WebShopRepository,
        apiEndpoint: string,
        loginEndpoint: string,
    ) {
        this._apiEndpoint = apiEndpoint;
        this._loginEndpoint = loginEndpoint;
        this._userRepository = userRepository;
        this._cartSizeSubject = new BehaviorSubject<number>(0);
        localStorage.language = localStorage.language || 'nl-NL';
        this._languageSubject = new BehaviorSubject<string>(localStorage.language);
        this.languageSubscription = this._languageSubject.subscribe((language) => (localStorage.language = language));
        this._cart = new ShoppingCart(this, webShopRepository);
    }

    public beginLogin(): void {
        window.location.href = this._loginEndpoint;
    }

    public beginLogout(): void {
        this._userRepository.logout().subscribe((resource) => {
            if (resource.isSuccess && resource.data) {
                window.location.href = resource.data.logoutUrl;
            }
        });
    }

    public refreshUser(): void {
        this._userSubscription.unsubscribe();
        this._userSubscription = this._userRepository
            .findCurrent()
            .subscribe((resource) => this._userSubject.next(resource));
    }

    public get userObservable(): Observable<Resource<User>> {
        return this._userSubject.asObservable();
    }

    public get languageObservable(): Observable<string> {
        return this._languageSubject.asObservable();
    }

    public setLanguage(value: string) {
        if (this._languageSubject.value !== value) {
            this._languageSubject.next(value);
        }
    }

    public get language() {
        return this._languageSubject.getValue();
    }

    public get cart() {
        return this._cart;
    }

    public getFileUrl(fileId: number) {
        if (fileId === undefined) return '';
        return this._apiEndpoint + '/file/' + fileId + '/download';
    }

    public getThumbUrl(fileId: number) {
        if (fileId === undefined) return '';
        return this._apiEndpoint + '/file/' + fileId + '/thumb';
    }

    public getNgtFileUrl(fileId: number) {
        if (fileId === undefined) return '';
        return `${this._apiEndpoint}/rest/ngt-media/film/${fileId}`;
    }

    public getNgtThumbUrl(fileId: number) {
        if (fileId === undefined) return '';
        return `${this._apiEndpoint}/rest/ngt-media/thumb/${fileId}`;
    }

    public setCartSize(amount: number) {
        this._cartSizeSubject.next(amount);
    }

    public get cartSizeObservable(): Observable<number> {
        return this._cartSizeSubject.asObservable();
    }
}
