import Vue from 'vue';
import Router, { NavigationGuard } from 'vue-router';
import { Component } from 'vue-property-decorator';
import Home from '@/components/screens/Home.vue';
import Page from '@/components/screens/ContentPage.vue';
import ShopHome from '@/components/screens/shop/Home.vue';
import Product from '@/components/screens/shop/Product.vue';
import Basket from '@/components/screens/shop/Basket.vue';
import Checkout from '@/components/screens/shop/Checkout.vue';
import Publications from '@/components/screens/Publications.vue';
import Completed from '@/components/screens/shop/Completed.vue';
import AccessibleProductsScreen from '@/components/screens/AccessibleProductsScreen.vue';
import Employees from '@/components/screens/Employees.vue';
import { globalState } from '@/container';
import { filter, map } from 'rxjs/operators';

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

Vue.use(Router);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/publicaties',
        name: 'publications',
        component: Publications,
    },
    {
        path: '/medewerkers',
        name: 'employees',
        component: Employees,
    },
    {
        path: '/shop',
        name: 'shop',
        component: ShopHome,
    },
    {
        path: '/shop/basket',
        name: 'basket',
        component: Basket,
    },
    {
        path: '/shop/checkout',
        name: 'checkout',
        component: Checkout,
    },
    {
        path: '/shop/completed/:order',
        name: 'shopCompleted',
        component: Completed,
    },
    {
        path: '/shop/product/:product',
        name: 'product',
        component: Product,
    },
    {
        path: '/products',
        name: 'accessibleProducts',
        component: AccessibleProductsScreen,
    },
    {
        path: '/:page',
        name: 'page',
        component: Page,
    },
];

let verifiedSessionAlive = false;

const authGuard: NavigationGuard = (to, from, next) => {
    if (!verifiedSessionAlive) {
        globalState.refreshUser();
        globalState.userObservable
            .pipe(
                filter((t) => !t.isLoading),
                map((t) => (t.isSuccess && t.data ? t.data : null)),
            )
            .subscribe((user) => {
                if (!user) {
                    const hslFlag = document.cookie.replace(/(?:^|.*;\s*)HSL-FLAG\s*=\s*([^;]*).*$|^.*$/, '$1');
                    if (hslFlag === 'true') {
                        globalState.beginLogin();
                        return;
                    }
                }
                verifiedSessionAlive = true;
                next();
            });
    } else {
        next();
    }
};

const router = new Router({
    mode: 'history',
    routes,
});

router.beforeEach(authGuard);

export default router;
