import NavigationItem from '@/repository/data/NavigationItem';
import { Observable } from 'rxjs';
import ContentPageData from '@/repository/data/ContentPageData';
import PinnedPageData from '@/repository/data/PinnedPageData';
import WeeklySignData from '@/repository/data/WeeklySignData';
import HttpClient from '@/util/http/HttpClient';
import ApiWrapper from '@/repository/data/ApiWrapper';
import DirectNavigationData from '@/repository/data/DirectNavigationData';
import ProductData from '@/repository/data/ProductData';
import ProductCategoryData from '@/repository/data/ProductCategoryData';
import OrderData from '@/repository/data/OrderData';
import OrderResponse from '@/repository/data/OrderResponse';
import Page from '@/repository/Page';
import Pageable from '@/repository/data/Pageable';
import Publication from '@/repository/data/Publication';
import User from '@/repository/data/User';
import FooterData from '@/repository/data/FooterData';
import ShopHeaderData from '@/repository/data/ShopHeader';
import EmployeeData from '@/repository/data/EmployeeData';
import FrontPageContent from '@/repository/data/FrontPageContent';

export default class NgcApi {
    private readonly client: HttpClient;
    private readonly apiEndpoint: string;
    private readonly logoutEndpoint: string;
    private readonly baseUrl: string;

    constructor(client: HttpClient, apiEndpoint: string, logoutEndpoint: string) {
        this.client = client;
        this.apiEndpoint = apiEndpoint;
        this.logoutEndpoint = logoutEndpoint;
        this.baseUrl = apiEndpoint + '/rest/';
    }

    public getSelf(): Observable<{ name: string } | null> {
        return this.client.get('self', { baseUrl: this.apiEndpoint });
    }

    public getCurrentUser(): Observable<ApiWrapper<User>> {
        return this.client.get('user/current', { baseUrl: this.baseUrl });
    }

    public logout(): Observable<{ logoutUrl: string }> {
        return this.client.post(this.logoutEndpoint);
    }

    public getNavigationItems(language: string): Observable<ApiWrapper<Array<NavigationItem>>> {
        return this.client.get(language + '/sidebar-navigation', { baseUrl: this.baseUrl });
    }

    public getPinnedPages(language: string): Observable<ApiWrapper<Array<PinnedPageData>>> {
        return this.client.get(language + '/pinned', { baseUrl: this.baseUrl });
    }

    public getWeeklySign(language: string): Observable<ApiWrapper<WeeklySignData>> {
        return this.client.get(language + '/weekly-sign', { baseUrl: this.baseUrl });
    }

    public getContentPage(slug: string): Observable<ApiWrapper<ContentPageData>> {
        return this.client.get('page/' + slug, { baseUrl: this.baseUrl });
    }

    public getFrontPageContent(language: string): Observable<ApiWrapper<FrontPageContent>> {
        return this.client.get(language + '/front-page-content', { baseUrl: this.baseUrl });
    }

    public getDirectNavigation(language: string): Observable<ApiWrapper<Array<DirectNavigationData>>> {
        return this.client.get(language + '/direct-navigation', { baseUrl: this.baseUrl });
    }

    public getFooter(language: string): Observable<ApiWrapper<FooterData>> {
        return this.client.get(language + '/footer', { baseUrl: this.baseUrl });
    }

    public getShopHeader(language: string): Observable<ApiWrapper<ShopHeaderData>> {
        return this.client.get(language + '/shop/header', { baseUrl: this.baseUrl });
    }

    public getProductCategories(language: string): Observable<ApiWrapper<Array<ProductCategoryData>>> {
        return this.client.get(language + '/shop/categories', { baseUrl: this.baseUrl });
    }

    public getRecommendedProducts(language: string, productId: number): Observable<ApiWrapper<Array<ProductData>>> {
        return this.client.get(language + '/shop/products/' + productId + '/recommended', { baseUrl: this.baseUrl });
    }

    public getProducts(
        language: string,
        pageable: Pageable,
        categoryFilter: number | null,
    ): Observable<ApiWrapper<Page<ProductData>>> {
        const params = this.convertPageableBody(pageable, categoryFilter);
        return this.client.get(language + '/shop/products', { baseUrl: this.baseUrl, params });
    }

    public getOrganisationProducts(
        language: string,
        pageable: Pageable,
        categoryFilter: number | null,
    ): Observable<ApiWrapper<Page<ProductData>>> {
        const params = this.convertPageableBody(pageable, categoryFilter);
        return this.client.get(language + '/shop/products/organisation', { baseUrl: this.baseUrl, params });
    }

    public getProductWithSubscriptionId(subscriptionId: number): Observable<ApiWrapper<ProductData | null>> {
        return this.client.get('NL-nl/shop/products/subscriptionDefinition/' + subscriptionId, {
            baseUrl: this.baseUrl,
        });
    }

    public getProductById(language: string, productId: number): Observable<ApiWrapper<ProductData>> {
        return this.client.get(language + '/shop/products/' + productId, { baseUrl: this.baseUrl });
    }

    public getCouponAmount(language: string, order: OrderData, coupon: String): Observable<ApiWrapper<number>> {
        return this.client.post(language + '/shop/payments/discount/' + coupon, order, { baseUrl: this.baseUrl });
    }

    public postProductOrder(language: string, order: OrderData): Observable<ApiWrapper<OrderResponse>> {
        return this.client.post(language + '/shop/payments/order', order, { baseUrl: this.baseUrl });
    }

    public getPublicationsPage(
        language: string,
        pageable: Pageable,
        query: string | null,
    ): Observable<ApiWrapper<Page<Publication>>> {
        const params = this.convertPageableBody(pageable, query);
        return this.client.get(language + '/publications', { baseUrl: this.baseUrl, params });
    }

    public getEmployeePage(language: string): Observable<ApiWrapper<Array<EmployeeData>>> {
        return this.client.get(language + '/employees', { baseUrl: this.baseUrl });
    }

    private convertPageableBody(pageable: Pageable, query: any = null): any {
        const params: any = {
            page: pageable.page,
            size: pageable.size,
            sort: pageable.sort.map((t) => t.property + ',' + t.direction).join(','),
        };
        if (query) {
            params.q = query;
        }
        return params;
    }
}
