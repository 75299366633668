import HttpClient from '@/util/http/HttpClient';
import NgcApi from '@/repository/api/NgcApi';
import NavigationItemsRepository from '@/repository/NavigationItemsRepository';
import PageRepository from '@/repository/PageRepository';
import WebShopRepository from '@/repository/WebShopRepository';
import PublicationsRepository from '@/repository/PublicationsRepository';
import GlobalState from '@/state/GlobalState';
import UserRepository from '@/repository/UserRepository';
import EmployeeRepository from '@/repository/EmployeeRepository';

const wwwEndpoint = process.env.VUE_APP_WWW_ENDPOINT;
const apiEndpoint = wwwEndpoint + '/api';
const loginEndpoint = wwwEndpoint + '/oauth2/authorization/ngc-auth';
const logoutEndpoint = wwwEndpoint + '/oauth2/logout';
const httpClient = new HttpClient({});
const api = new NgcApi(httpClient, apiEndpoint, logoutEndpoint);
const userRepository = new UserRepository(api);
export const navigationItemsRepository = new NavigationItemsRepository(api);
export const pageRepository = new PageRepository(api);
export const webShopRepository = new WebShopRepository(api);
export const publicationsRepository = new PublicationsRepository(api);
export const employeeRepository = new EmployeeRepository(api);
export const globalState = new GlobalState(userRepository, webShopRepository, apiEndpoint, loginEndpoint);
